<template>
  <looker-report :url="url" />
</template>

<script>
export default {
  components: {
    LookerReport: () => import('@/modules/external_bi/components/LookerReport')
  },
  data: () => ({
    url: 'https://lookerstudio.google.com/embed/reporting/49ab4b63-27bd-4efd-bd30-117c358dfe35/page/1lkTE'
  })
}
</script>
